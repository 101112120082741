<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field v-model="input.email" label="Почта"> </v-text-field>
    <v-text-field
      v-model="input.password"
      label="Пароль"
      type="password"
      :rules="[$validate.required, $validate.passMin]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.username"
      label="Имя"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-select
      v-model="input.roleId"
      label="Права"
      :items="ROLE.allRoles"
      item-text="name"
      item-value="id"
      :rules="[$validate.required]"
    >
    </v-select>
    <v-select
      v-model="input.departmentIds"
      label="Филиал"
      :items="USER.userInfo.departments"
      item-text="name"
      item-value="id"
      :rules="[$validate.required]"
      multiple
    >
    </v-select>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import user from "@/model/users";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {},
    };
  },
  computed: {
    ...mapGetters({
      ROLE: "Role/STATE",
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
    }),
  },
  async created() {
    let body = {};
    await this.getPowers();
    await this.getDepartments(this.$root.city);
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      body = await this.getUser(this.$route.params.id);
    }
    let model = new user();
    this.input = model.setInput(this.mode, body);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getUser: "User/GET_USER",
      addUser: "User/ADD_USER",
      editUser: "User/EDIT_USER",
      getPowers: "Role/GET_ALL_ROLES",
      getDepartments: "Department/GET_DEPARTMENT",
    }),
    async addButton() {
      this.loading = true;
      let response = await this.addUser(this.input);
      if (response.type === "success") {
        this.$router.push("/users");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
    async editButton() {
      this.loading = true;
      let response = await this.editUser(this.input);
      if (response.type === "success") {
        this.$router.push("/users");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
  },
};
</script>
