export default class test {
  constructor() {
    this.input = {
      email: "",
      password: "",
      username: "",
      roleId: 0,
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.email = data.user.email;
      this.input.password = data.user.password;
      this.input.username = data.user.username;
      this.input.roleId = data.user.role.id;
      this.input.id = data.user.id;
    }
    return this.input;
  }
  setPages(data) {
    if (data) {
      this.pages = {
        count: data.totalPages - 1,
        current: data.pageable.pageNumber,
        elements: data.totalElements,
      };
    }
    return this.pages;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.content.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data.content[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data.content[i].createdAt,
              type: "string",
            },
            {
              id: 3,
              name: data.content[i].updatedAt,
              type: "string",
            },
            {
              id: 4,
              name: data.content[i].phone,
              type: "string",
            },
            {
              id: 5,
              name: data.content[i].username,
              type: "string",
            },
            {
              id: 6,
              name: data.content[i].email,
              type: "string",
            },
            {
              id: 7,
              name: data.content[i].role.name,
              type: "string",
            },
            {
              id: 8,
              name: data.content[i].deleted,
              type: "string",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
